<template>
<div>
    <div class="addressform row ml-10" >
        
        <div class="col-md-4" v-for="item in addressess" :key="item.id">
            <div @click="selectaddress(item)" class="address-block">
                <span class="address-delete" @click="deleteaddress(item)"><i class='bx bx-trash' style="color:red"></i></span>
                
                <i class='bx bxs-circle'></i>{{getCompanyMasterLabelByType(item.staytype,COMPANY_MASTER_STAY_TYPE)}}<br>
                <span>{{ item.houseno}},</span>
                <span v-if="street!=null">{{ item.street }},<br></span>
                {{ item.landmark }},<br>
                {{getMasterLabelByType(item.city,MASTER_CITIES)}},<br>
                {{ item.zipcode }}
                {{ item.country }}
            </div>
            <!-- {{address.zipcode}} -->
        </div>
    </div>
    <div  class="addressform row ml-10" >
        <div class="form-group col-md-12 row Authority" style="">
            <label class="col-lg-4 col-md-3 control-label" v-if="office==1">Shop / Office No</label>
            <label class="col-lg-4 col-md-3 control-label" v-else >House No</label>
            
            <div class="col-lg-8 col-md-9">
                <input type="text" class="form-control form-cascade-control input-small" v-model="houseno">
            </div>
        </div>
        <div class="form-group col-md-12 row Authority" style="">
            <label class="col-lg-4 col-md-3 control-label">Street</label>
            <div class="col-lg-8 col-md-9">
                <textarea class="form-control form-cascade-control input-small" v-model="street" cols="20"  rows="2" style="height:80px;" ></textarea>
            </div>
        </div>
        
        <div class="form-group col-md-12 row Authority">
            <label class="col-lg-4 col-md-3 control-label">Landmark</label>
            <div class="col-lg-8 col-md-9">
                <input type="text" class="form-control form-cascade-control input-small" v-model="landmark">
            </div>
        </div>
        <div class="form-group col-md-12 row Authority">
            <label class="col-lg-4 col-md-3 control-label">City</label>
            <div class="col-lg-8 col-md-9">
                <div v-if="office==1">
                    <input type="text" class="form-control form-cascade-control input-small" v-model="city">
                </div>
                <div v-else >
                    <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="city" >
                        <option v-for="(item) in getmastertype(MASTER_CITIES)" v-bind:key="item.labelid" :value="item.labelid">{{ item.label }}</option>
                    </select>
                </div>
                
               
            </div>

        </div>
        <div class="form-group col-md-12 row Authority">
            <label class="col-lg-4 col-md-3 control-label">Pincode</label>
            <div class="col-lg-8 col-md-9">
                <input type="number" max="6" class="form-control form-cascade-control input-small" v-model="zipcode" @input="zipcodeValidation()">
                <div class="text-end ">{{ this.zipcode.length }}/6</div>
            </div>
        </div>
        <!-- <div class="form-group col-md-12 row Authority">
            <label class="col-lg-4 col-md-3 control-label">Address type</label>
            <div class="col-lg-8 col-md-9">
                <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="staytype" required>
                    <option v-for="(item) in getcompanymastertype(COMPANY_MASTER_STAY_TYPE)" v-bind:key="item.labelid" :value="item.labelid">{{ item.label }}</option>
                </select>
            </div>
        </div> -->
        <div class="form-group col-md-12 Authority text-center">
            <button type="button" v-if="!editadd" class="btn btn-success" @click="updateselectedaddress()">Save Address</button>
            <button type="button" v-if="editadd" class="btn btn-success" @click="editselectedaddress()">Edit Address</button>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../utilities/Constants.vue'
export default {
    mixins:[Constants],
    props:['office'],   
    data(){
        return {
            street:'',
            city:'',
            landmark:'',
            houseno:'',
            zipcode:'',
            staytype:0,
            setaddress:[],
            editadd:false,
            selectindex:0
        }
    },
    mounted(){
        if(this.addressess?.length>0){
            this.setaddress=this.addressess
        }
    },
   computed:{
        ...mapGetters(['addressess'])
   },
    methods:{
        initializedata(){
            this.street=''
            this.city=0
            this.landmark=''
            this.houseno=''
            this.zipcode=''
            this.staytype=0
            // this.setaddress=[]
        },
        updateselectedaddress(){
            if(this.street!='' || this.city!=''){
                let param={
                street:this.street,
                city:this.city,
                landmark:this.landmark,
                zipcode:this.zipcode,
                houseno:this.houseno,
                staytype:this.staytype
            }
            this.setaddress.push(param)
            this.$store.commit('assignaddressess',this.setaddress)
            console.log("Addressess")
            console.log(this.addressess)
            this.initializedata()
            }else{
                alert("Atleast enter street or city.")
            }
           
        },
        selectaddress(item){
            this.editadd=true
            this.street=item.street
            this.city=item.city
            this.landmark=item.landmark
            this.houseno=item.houseno
            this.zipcode=item.zipcode
            this.staytype=item.staytype
            // this.setaddress=[]
            this.selectindex=this.addressess.indexOf(item)
            console.log("index "+this.selectindex)
        },  
        editselectedaddress(){
            if(this.addressess.length>0){
                this.addressess.map((i,index)=>{
                    if(index==this.selectindex){
                        i.street=this.street,
                        i.city=this.city,
                        i.landmark=this.landmark,
                        i.zipcode=this.zipcode,
                        i.houseno=this.houseno,
                        i.staytype=this.staytype
                    }
                })
            }
            this.editadd=false
            this.initializedata()
        },
        deleteaddress(item){
            if(this.addressess.length>0){
                if(confirm('Do you want to delete Address')){
                    let index=this.addressess.indexOf(item);
                    this.addressess.splice(index,1)
                }
                
            }
        }
    }
}
</script>