<template>
    <div style="background-color:white">
        <div class="col-md-12" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                    <i class="fa fa fa-users animated bounceInDown show-info"></i>
                    Add Customer
                </h3>
            </div>
        </div>
        <div class="info-box  bg-seagreen  text-white">
            <div class="info-icon bg-danger-dark">
                <i class="fa fa fa-user fa-2x"></i>
            </div>
            <div class="info-details">
                <h4>Add Customer Personal Details</h4>
            </div>
        </div>

        <form class="col-md-12" @submit.prevent="submit" @keydown="keydownform($event)">

            <div class="panel panel-cascade">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">

                        <div class="form-group row Authority" style="">
                            <label class="col-lg-4 col-md-3 control-label"></label>
                            <div class="col-lg-4 col-md-4 ">

                                <img :src="profileimageurl" width="100px" height="100px" />
                                <upload></upload>
                            </div>
                        </div>
                        <div class="col-md-10">

                            <referral-selector col="col-lg-3 col-md-3" label="Introducer Id"></referral-selector>

                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Registation Type</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.registation_type"
                                        class="form-control delradius form-cascade-control">
                                        <!-- <option v-for="option in getmastertype(MASTER_REGISTRATION_TYPE)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" /> -->
                                        <option :value="1" v-if="loginusercheck(127)">Permanent</option>
                                        <option :value="2" v-if="loginusercheck(128)">Temporary</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row CHK" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class=" col-lg-4 col-md-3 control-label">
                                    Name
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.name"
                                        data-val="true" data-val-required="Required" id="NAME" name="NAME" type="text">
                                </div>
                            </div>
                            <div class="form-group row CHK" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class=" col-lg-4 col-md-3 control-label">
                                    <select class="form-cascade-control input-small" style="width:64%;padding:3px;"
                                        v-model="form.relationship_type">
                                        <option v-for="option in getmastertype(MASTER_RELATIONSHIP_TYPES)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                    </select>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.relation"
                                        id="FATHER_NAME" name="FATHER_NAME" type="text">
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Gender</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.gender" class="form-control delradius form-cascade-control">
                                        <option v-for="option in getmastertype(MASTER_GENDER)" v-bind:key="option.labelid"
                                            v-bind:value="option.labelid" v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">DOB</label>
                                <div class="col-lg-6 col-md-9">
                                    <!-- <input class="form-control form-cascade-control input-small"  v-model="form.dob" id="DOB" name="DOB"  type="date" > -->
                                    <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                        v-model="form.dob">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <div class="flex items-center">
                                                <button type="button" class="date-picker" @click="togglePopover()">
                                                    <i class='bx bx-calendar'></i>
                                                </button>
                                                <input :value="inputValue" @click="togglePopover()"
                                                    class="form-control form-cascade-control input-small"
                                                    placeholder="DD/MM/YYYY" readonly />
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                </div>
                            </div>
                            <address-form v-if="form.registation_type == 1 || form.registation_type == 2"></address-form>

                            <div class="form-group row CHK" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">
                                    Zone
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <select class="form-cascade-control input-small" style="width:64%;padding:3px;"
                                        v-model="form.zoneid">
                                        <option v-for="option in getmastertype(MASTER_ZONE_TYPES)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Add Location Place</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.location"
                                        data-val-required="Required" type="text">
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                <div class="col-lg-6 col-md-9">
                                    <mobiles></mobiles>
                                </div>
                            </div>

                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Mode of Re-Payment</label>
                                <div class="col-lg-6 col-md-9">
                                    <select class="form-control form-cascade-control input-small"
                                        v-model="form.paymentmodeid">
                                        <option v-for="item in registers" :key="item.id" :value="item.id">{{ item.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Nature of Residence</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.residence" class="form-control delradius form-cascade-control">
                                        <option :value="0">----Select----</option>
                                        <option v-for="option in getmastertype(MASTER_RESIDENCE)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Duration of Residence</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.dor"
                                        data-val-required="Required" type="number">

                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Occuption</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.s_income" class="form-control delradius form-cascade-control"
                                        required>
                                        <option v-for="option in getmastertype(MASTER_OCCUPATION_TYPE)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Occupation name and Address</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.occupation"
                                        id="OCCUPATION" name="OCCUPATION" type="text" required>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Profession</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.professionid" class="form-control delradius form-cascade-control"
                                        required>
                                        <option :value="0">----Select---</option>
                                        <option v-for="option in getcompanymastertype(COMPANY_MASTER_PROFESSION)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Registration Date</label>
                                <div class="col-lg-6 col-md-9">
                                    <!-- <input class="form-control form-cascade-control input-small" data-val="true"
                                        v-model="form.registation_date" data-val-required="Required" id="REGDATE"
                                        name="REGDATE" type="date"> -->
                                    <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                        v-model="form.registation_date">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <div class="flex items-center">
                                                <button type="button" class="date-picker" @click="togglePopover()">
                                                    <i class='bx bx-calendar'></i>
                                                </button>
                                                <input :value="inputValue" @click="togglePopover()"
                                                    class="form-control form-cascade-control input-small"
                                                    placeholder="DD/MM/YYYY" readonly />
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                </div>
                            </div>


                            <work-address-form v-if="form.registation_type == 1"></work-address-form>
                            <div class="form-group row" v-if="form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Religion</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.religion" class="form-control delradius form-cascade-control"
                                        required>
                                        <option v-for="option in getmastertype(MASTER_RELIGION)" v-bind:key="option.labelid"
                                            v-bind:value="option.labelid" v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Marital Status</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.m_status" class="form-control delradius form-cascade-control"
                                        required>
                                        <option v-for="option in getmastertype(MASTER_MARITAL_STATUS)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.m_status == 1 && form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Marrige Date</label>
                                <div class="col-lg-6 col-md-9">
                                    <!-- <input class="form-control form-cascade-control input-small"
                                        v-model="form.marrigedate" type="date"> -->
                                    <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                        v-model="form.marrigedate">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <div class="flex items-center">
                                                <button type="button" class="date-picker" @click="togglePopover()">
                                                    <i class='bx bx-calendar'></i>
                                                </button>
                                                <input :value="inputValue" @click="togglePopover()"
                                                    class="form-control form-cascade-control input-small"
                                                    placeholder="DD/MM/YYYY" readonly />
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                </div>
                            </div>

                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2"
                                :class="{ 'form-group--error': $v.form.adhar.$error }">
                                <label class="col-lg-4 col-md-3 control-label">Adhar Card No.</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.adhar"
                                        data-val-required="Required" type="text">
                                    <i v-if="validateadhar"
                                        class='bx bxs-checkbox-checked bx-tada adhar-validate-success'></i>
                                    <!-- <span class="error" v-if="!$v.form.adhar.required">Field is required</span> -->
                                    <span class="error" v-if="!$v.form.adhar.minLength">Adhar must have
                                        {{ $v.form.adhar.$params.minLength.min }} letters.</span>
                                    <span class="error" v-if="!$v.form.adhar.maxLength">Adhar must have
                                        {{ $v.form.adhar.$params.maxLength.max }} letters.</span>
                                    <!-- <span class="error" v-if="!$v.form.adhar.isUnique">No. of Emi Should not be 0</span> -->
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Pan Card No.</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.pan"
                                        data-val-required="Required" type="text">
                                    <div class="col-md-12 form-error"></div>
                                </div>
                            </div>
                            <div class="form-group row CHK" v-if="form.registation_type == 1">
                                <label class=" col-lg-4 col-md-3 control-label">
                                    <select class="form-cascade-control input-small" style="width:64%;padding:3px;"
                                        v-model="form.document_type">
                                        <option v-for="option in getmastertype(MASTER_DOCUMENT)" v-bind:key="option.labelid"
                                            v-bind:value="option.labelid" v-text="option.label" />
                                    </select>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small"
                                        v-model="form.documentvalue" id="FATHER_NAME" name="FATHER_NAME" type="text">
                                </div>
                            </div>

                            <div class="form-group row" v-if="form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Email</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.email"
                                        data-val-required="Required" type="text">
                                    <div class="col-md-12 form-error"></div>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Customer Type</label>
                                <div class="col-lg-6 col-md-9">
                                    <select class="form-control form-cascade-control input-small"
                                        v-model="form.customer_type">
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                    </select>
                                    <div class="col-md-12 form-error"></div>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Credit Limit</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.creditlimit">
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Verified By</label>
                                <div class="col-lg-6 col-md-9">
                                    <select v-model="form.verifiedbyid"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">
                                            <label for="">{{ item.generatedid }}</label> -{{ item.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 2 || form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Reference Name</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" type="text"
                                        v-model="form.refername">
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 2 || form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Reference Mobile No.</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" type="number"
                                        v-model="form.refermobileno">
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 2 || form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Reference Address</label>
                                <div class="col-lg-6 col-md-9">
                                    <textarea class="form-control form-cascade-control input-small"
                                        v-model="form.referaddress" cols="20" rows="2" style="height:83px;"></textarea>
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 2 || form.registation_type == 1">
                                <label class="col-lg-4 col-md-3 control-label">Reference Relationship</label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" type="text"
                                        v-model="form.referrelation">
                                </div>
                            </div>
                            <div class="form-group row" v-if="form.registation_type == 1 || form.registation_type == 2">
                                <label class="col-lg-4 col-md-3 control-label">Additional Add. & Other Customer Hint</label>
                                <div class="col-lg-6 col-md-9">
                                    <textarea class="form-control form-cascade-control input-small" v-model="form.cus_hint"
                                        cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                                </div>
                            </div>
                            <div class="form-group row row leadbycustomer" style="display: none">
                                <div class="col-lg-6 col-md-9">
                                    <span id="fill"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="panel panel-cascade" style="padding-top:20px">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <div class="col-md-12 row">
                            <id-proof></id-proof>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="flex-around-row" style="text-align:center;">
                    <input v-if="!clickpdf" :readonly="clickpdf" type="button"
                        style="background-color:#495b79!important;margin-top:23px;width:100px" value="Submit"
                        @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">
                    <span v-if="clickpdf" class="spinner"></span>
                    <input type="button" style="background-color:#495b79!important;margin-top:23px;width:100px"
                        value="Cancel" @click="cancel()" class="btn bg-primary text-white btn-sm chkcondition">

                    <!-- <input type="button" style="background-color:#495b79!important;margin-top:23px;width:100px"
                        value="Location" @click="getlocation()" class="btn bg-primary text-white btn-sm chkcondition"> -->
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AddressForm from '../../components/address/AddressForm.vue'
import WorkAddressForm from '../../components/address/WorkAddressForm.vue'
import Mobiles from '../../components/mobile/Mobiles.vue'
import IdProof from '../../components/idproof/IdProof.vue'
import Constants from '../../components/utilities/Constants.vue'
import Upload from '../../components/upload/Upload.vue'
import ReferralSelector from '../../components/user/ReferralSelector.vue'
import { minLength, maxLength } from 'vuelidate/lib/validators'
export default {
    mixins: [Constants],
    props: ['label'],
    components: { Mobiles, AddressForm, IdProof, Upload, ReferralSelector, WorkAddressForm },
    data() {
        return {
            form: new window.Form({
                id: 0,
                name: '',
                adhar: 0,
                sponser_type: 0,
                registation_type: 0,
                parentid: 0,
                relation: '',
                mobiles: null,
                addressess: null,
                idproofs: null,
                s_income: 1,
                m_status: 1,
                marrigedate: null,
                occupation: '',
                religion: 1,
                gender: 1,
                dob: '',
                registation_date: '',
                relationship_type: 1,
                zoneid: 1,
                location: '',
                cus_hint: '',
                document_type: 1,
                iscustomer: 1,
                w_name: '',
                profileimage: null,
                email: '',
                documentvalue: '',
                pan: '',
                residence: 0,
                dor: '',
                customer_type: '',
                creditlimit: '',
                paymentmodeid: 0,
                verifiedbyid: 0,
                latitude: '',
                longitude: '',
                refername: '',
                refermobileno: '',
                referaddress: '',
                referrelation: '',
                professionid: 0
            }),
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            clickpdf: false,
            validateadhar: true
        }
    },
    validations: {
        form: {
            adhar: {
                minLength: minLength(12),
                maxLength: maxLength(12),
            }
        }
    },
    computed: {
        ...mapGetters([
            'perpagerecord', 'viewno', 'edititem', 'profileimage', 'uploadedimage', 'mobiles',
            'addressess', 'referraluser', 'idproofs', 'registers', 'loggedinuser', 'employees', 'uploadids'
        ]),
        profileimageurl() {
            if (this.profileimage != null) {
                return this.profileimage.fullurl;
            }
            return '#'
        }
        //   mastertypes(){
        //     console.log(this.$store.state.mastertypes)
        // 	return this.$store.state.mastertypes;
        // },


    },
    mounted() {

        this.initializedata()
        this.$store.dispatch("fetchregisters")
        this.$store.dispatch("fetchemployees")
        this.$store.dispatch('fetchcompanymaster')
        if (this.$router.currentRoute.path == '/addcustomer') {

            this.$store.commit("assignedititem", null)
            this.reset();
            this.initializedata();

        }
        setTimeout(() => {
            this.checkvalidationaccess()
        }, 2000);

        // this.$getLocation(options)
        // .then(coordinates => {
        //     console.log("Cordinates :"+coordinates);
        // });

        this.$getLocation({
            enableHighAccuracy: true, //defaults to false
            timeout: Infinity, //defaults to Infinity
            maximumAge: 10 //defaults to 0
        })
            .then(coordinates => {
                console.log("Cordinates :" + coordinates.lan);
                this.latitude = coordinates.lat
                this.longitude = coordinates.lan
            });
    },
    methods: {
        getlocation() {
            this.$getLocation({
                enableHighAccuracy: true, //defaults to false
                timeout: Infinity, //defaults to Infinity
                maximumAge: 0 //defaults to 0
            })
                .then(coordinates => {
                    console.log("Cordinates :" + (coordinates.accuracy));
                });
        },
        initializedata() {
            //this.reset();
            if (this.edititem != null) {
                console.log("inside edit")
                this.form.id = this.edititem.id;
                this.form.name = this.edititem.name;
                this.form.sponser_type = this.edititem.sponser_type;
                this.form.document_type = this.edititem.document_type
                this.form.documentvalue = this.edititem.documentvalue
                this.form.zoneid = this.edititem.zoneid;
                this.form.location = this.edititem.location
                this.form.relation = this.edititem.relation;
                this.form.relationship_type = this.edititem.relationship_type;
                this.form.s_income = this.edititem.s_income;
                this.form.occupation = this.edititem.occupation;
                this.form.w_name = this.edititem.w_name;
                this.form.pan = this.edititem.pan;
                this.form.residence = this.edititem.residence;
                this.form.dor = this.edititem.dor;
                this.form.customer_type = this.edititem.customer_type
                this.form.creditlimit = this.edititem.creditlimit
                this.form.religion = this.edititem.religion;
                this.form.paymentmodeid = this.edititem.paymentmodeid
                this.form.verifiedbyid = this.edititem.verifiedbyid
                this.form.gender = this.edititem.gender;
                this.form.dob = this.edititem.dob;
                this.form.registation_type = this.edititem.registation_type;
                this.form.registation_date = this.edititem.registation_date;
                this.form.referrelation = this.edititem.referrelation
                this.form.cus_hint = this.edititem.cus_hint;

                this.form.m_status = this.edititem.m_status;
                this.form.adhar = this.edititem.adhar;
                this.form.email = this.edititem.email;
                this.form.professionid = this.edititem.professionid
                this.form.refername = this.edititem.refername,
                    this.form.refermobileno = this.edititem.refermobileno,
                    this.form.referaddress = this.edititem.referaddress
                this.form.referrelation = this.edititem.referrelation
                this.$store.commit('assignmobiles', this.edititem.mobiles == null ? [] : this.edititem.mobiles)
                this.$store.commit('assignaddressess', this.edititem.addressess == null ? [] : this.edititem.addressess)
                this.$store.commit('assignidproofs', this.edititem.idproofs == null ? [] : this.edititem.idproofs)
                this.$store.commit('assignuploadids', this.edititem.idproofs == null ? [] : this.edititem.idproofs)
                this.$store.commit('assignreferraluser', this.edititem.referraluser)
                if (this.edititem.addressess != null && this.edititem.addressess.length > 0) {
                    let normaladdress = this.edititem.addressess.filter(block => block.addresstype == 0)
                    if (typeof normaladdress !== 'undefined' && normaladdress.length > 0) {
                        this.$store.commit('assignselectedaddress', normaladdress[0])
                    }
                    let workaddress = this.edititem.addressess.filter(block => block.addresstype == 1)
                    if (typeof workaddress !== 'undefined' && workaddress.length > 0) {
                        this.$store.commit('assignselectedwaddress', workaddress[0])
                    }
                }
                if (this.edititem.profileimage != null) {
                    this.form.profileimageid = this.edititem.profileimage.id
                    this.$store.commit('assignprofileimage', this.edititem.profileimage)
                }
            } else {
                this.form = new window.Form({
                    id: 0,
                    name: '',
                    adhar: 0,
                    sponser_type: 0,
                    registation_type: 0,
                    parentid: 0,
                    relation: '',
                    mobiles: null,
                    addressess: null,
                    idproofs: null,
                    zone: '',
                    s_income: 1,
                    m_status: 1,
                    marrigedate: null,
                    occupation: '',
                    religion: 1,
                    gender: 1,
                    dob: '',
                    registation_date: '',
                    cus_hint: '',
                    iscustomer: 1,
                    w_name: '',
                    profileimage: null,
                    email: '',
                    relationship_type: 1,
                    zoneid: 1,
                    location: '',
                    document_type: 1,
                    documentvalue: '',
                    residence: 0,
                    dor: '',
                    customer_type: '',
                    creditlimit: '',
                    paymentmodeid: 0,
                    verifiedbyid: 0,
                    refername: '',
                    refermobileno: '',
                    referaddress: '',
                    referrelation: '',
                    professionid: 0
                }),
                    this.$store.commit('assignselectedwaddress', null)
                this.$store.commit('assignselectedaddress', null)
                this.$store.commit('assignuploadids', [])
                // this.uploadids=[]
            }
        },
        checkvalidationaccess() {
            if (this.loginusercheck(123)) {
                this.validateadhar = false
            }
        },
        submit() {
            this.checkvalidationaccess()
            // if(this.$v.$invalid){
            //     this.$notify({
            //         text:'Plz Fill All the details',
            //         type:'error',
            //         duration:'50000',
            //         speed:'300',
            //         closeOnClick:false
            //     })
            // }else{
            console.log(this.$v)
            this.clickpdf = true
            this.form.mobiles = this.mobiles
            this.form.addressess = this.addressess
            this.form.parentid = this.referraluser == null ? 0 : this.referraluser.id
            if (this.form.parentid == 0) {
                this.clickpdf = false
                alert("Introducer Can Not be empty")
            } else if (!this.form.mobiles.length > 0) {
                this.clickpdf = false
                alert("Mobile No. can not be empty")
            } else if (this.validateadhar && this.form.adhar == 0) {
                this.clickpdf = false
                alert("Adhar Card Must be filled")
            } else if (this.form.registation_date == '') {
                this.clickpdf = false
                alert("Registration Date Must be filled")
            } else if (this.form.verifiedbyid == 0) {
                this.clickpdf = false
                alert("Verified by must be filled")
            } else if (this.form.relation == '') {
                this.clickpdf = false
                alert("SO-WO-DO by must be filled")
            } else if (this.form.dob == '') {
                this.clickpdf = false
                alert("Date of Birth by must be filled")
            }
            else if (this.form?.addressess[0]?.zipcode == '') {
                this.clickpdf = false
                alert("Pincode is compulsory")
            }
            else {
                this.$store.commit('assignloadingstatus', 1)
                if (this.profileimage != null && this.form.profileimageid != this.profileimage.id)
                    this.form.profileimage = this.profileimage
                this.form.idproofs = this.uploadids
                this.form.submit(this, 'post', 'api/profile/createupdate')
                    .then(response => this.processResponse(response))
                    .catch(error => this.errordisplay(error));
            }
        },
        errordisplay(error) {
            console.log("Error", error?.errors?.mobiles)
            this.$store.commit('assignloadingstatus', 0)

            setTimeout(() => {
                this.clickpdf=false
            }, 3000); 

            console.log("in mobile ")
            this.$notify({
                text: error?.errors?.mobiles[0],
                type: 'error',
                duration: '80000',
                speed: '500',
                closeOnClick: false

            })
            this.$notify({
                text: error?.errors?.adhar[0],
                type: 'error',
                duration: '80000',
                speed: '500',
                closeOnClick: false

            })
            
        },
        processResponse(data) {
            this.$store.commit('updateappendcustomers', data.user)
            this.$store.commit('assignviewno', 1)
            this.$notify({
                text: 'Customer Registered Successfully',
                type: 'success',
                duration: '80000',
                speed: '500',
                closeOnClick: false
            })
            this.reset();
            this.$store.commit('assignloadingstatus', 0)
            setTimeout(() => {
                this.clickpdf = false
            }, 2000);
        },
        keydownform(event) {
            this.form.errors.clear(event.target.name);
            this.errormessage = '';
        },

        cancel() {
            this.reset();
            this.$store.commit('assignviewno', 1)
            this.$store.commit('assignloadingstatus', 0)
        },
        uploaded() {
            this.$store.commit('assignprofileimage', this.uploadedimage)
        }

    }

}
</script>
<style>
.adhar-validate-success {
    color: rgb(23, 152, 10);
    font-size: 45px;
    position: absolute;
    top: 0;
    right: 13px;
}

.adhar-validate-error {
    color: rgb(152, 10, 10);
    font-size: 45px;
    position: absolute;
    top: 0;
    right: 13px;
}
</style>