<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                    <i class="fa fa fa-list-alt animated bounceInDown show-info"></i>
                    DownLine
                    </h3>
                </div>
                <div class="col-md-6 ">
                    <div class="row" style="padding-top:18px;justify-content:flex-end;">
                        <div class="col-md-2" style="margin-right:25px">   
                            <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                        </div> 
                    </div>
            </div>
            </div>
        </div>
        <div class="col-md-12 pt-20 flex-between-row">
            <div class="col-md-8 row">
                <div class="col-md-3"> <label for=""><strong>No. of Rec.</strong> </label> </div>
                <div class="col-md-5">  
                    <select class="form-control col-mb-1 form-cascade-control" v-model="noofrec">
                        <option value=10>10</option>
                        <option value=25>25</option>
                        <option value=50>50</option>
                        <option value=100>100</option>
                    </select>
                </div>
            </div>
            <div>Total Downline: {{countno}}</div>
        </div>
       <div class="col-md-12 table-responsive">
            <div class=" panel">
                <div class="  panel-body" style="overflow-x:auto;padding-left:0px">
                    <table class="table table-bordered table-hover table-striped display">
                        <thead class="table-font" style="font-size:13px;">
                            <tr>
                                <th>#</th>
                                <th class="visible-lg">Customer ID</th>
                                <th class="visible-lg">NAME</th>
                                <th class="visible-lg">Contact</th>
                                <th>REGESTRATION DATE</th>
                                <th>ENTRY BY</th>
                                <th>SPONSORID</th>
                                <th>SPONSOR NAME</th>
                                
                            </tr>
                        </thead>
                        <tbody class="table-font">
                            <tr v-for="(item,index) in downline" v-bind:key="item.id">
                                <td>{{((currentpage-1) * noofrec) + (index+1)}}</td>
                                <td><a href="#">{{item.generatedid}}</a></td>
                                <td>{{item.name}}</td>
                                <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td> 
                                <td>{{moment(item.registation_date).format('DD-MM-YYYY')}}</td>
                                <td><span v-if="item.enteredby!=null">{{item.enteredby.name}}</span></td>
                                <td><span v-if="item.referraluser!=null">{{item.referraluser.generatedid}}</span></td>
                                <td><span v-if="item.referraluser!=null">{{item.referraluser.name}}</span></td>
                              </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12" style="margin-top: 20px">
                <nav aria-label="Page navigation example" style="text-align: right !important">   
                    <paginate
                        style="margin: 0px !important; float: right"
                        :page-count="noofpages"
                        :click-handler="clickCallback"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'">
                    </paginate>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Constants from '../../components/utilities/Constants.vue'
import Paginate from "vuejs-paginate";
export default {
    mixins:[Constants],
    components:{DisplayMobiles,Paginate},
    data() {
        return {
            totalamount:0,
            moment:moment,
            countno:0,
            currentpage:1,
            noofrec:10,
            downline:[]
        }
    },
    computed:{
        ...mapGetters([
		      'viewno','downlinecount'
	    ]),
        noofpages(){
            return Math.ceil(parseInt(this.countno) / parseInt(this.noofrec))
        },
    },
    mounted() {
        this.refresh()
    },
    methods:{
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh();
        },
        refresh(){
            console.log(this.$route.params.id)
            let param = {id:this.$route.params.id,currentpage:this.currentpage,noofrec:this.noofrec}
            this.$http.post('api/user/downline/fetch',param)
            .then((response) => this.processDownlineResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.$http.post('api/user/downline/fetchCount',param)
            .then((response) => this.processDownlineCountResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
           // this.$store.commit('assignviewno',3)
           
        },
        processDownlineResponse(data){
            this.downline=data
            this.$store.commit('assignviewno',3)
        },
        processDownlineCountResponse(data){
            // this.$store.commit('assigndownlinecount',data)
            this.countno=data
        },
        // processDetailResponseCount(data){
        //     this.totalamount=data
        //     console.log(data)
        // },
        back(){
           this.$store.commit('assignviewno',1)
           this.$router.push({name:"advisor"})
        },
        
    }
}
</script>
<style scoped>
.detailborder{
    padding: 15px;
    background-color: #eab132f5;
}
.amt{
    color:#485259;
    font-weight: 700;
}
</style>