<template>
    <div>
        <form class="col-md-12">
            <div class="panel panel-cascade">
                <div class="panel-body" style="background-color:#c5dadf;">
                    <div class="form-horizontal cascde-forms">
                        <h3 class="page-header">ADVISORS PAYOUT</h3>
                        <div class="form-group row CHK">
                            <label class=" col-lg-2 col-md-2 control-label text-r">
                                From Date
                            </label>
                            <div class="col-lg-3 col-md-3">
                                <!-- <input :disabled="edititem != null" class="form-control form-cascade-control input-small"
                                    type="date" v-model="fromdate"> -->
                                <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="fromdate">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div class="flex items-center">
                                            <button type="button"
                                                class="date-picker" :disabled="edititem != null"
                                                @click="togglePopover()">
                                                <i class='bx bx-calendar'></i>
                                            </button>
                                            <input :value="inputValue" @click="togglePopover()" :disabled="edititem != null"
                                                class="form-control form-cascade-control input-small"
                                                placeholder="DD/MM/YYYY" readonly />
                                        </div>
                                    </template>
                                </vc-date-picker>
                            </div>

                            <label class=" col-lg-3 col-md-3 control-label text-r">
                                To Date
                            </label>
                            <div class="col-lg-3 col-md-3">
                                <!-- <input :disabled="edititem != null" class="form-control form-cascade-control input-small"
                                    type="date" v-model="todate" @change="fetchbal()"> -->
                                <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="todate">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div class="flex items-center">
                                            <button type="button"
                                                class="date-picker" :disabled="edititem != null"
                                                @click="togglePopover()">
                                                <i class='bx bx-calendar'></i>
                                            </button>
                                            <input :value="inputValue" @click="togglePopover()" :disabled="edititem != null"
                                                class="form-control form-cascade-control input-small"
                                                placeholder="DD/MM/YYYY" readonly />
                                        </div>
                                    </template>
                                </vc-date-picker>
                            </div>
                            <div><button type="button" class="btn bg-success text-white username btnsearch" @click="fetchbal()">Search</button></div>
                        </div>
                        <div class="col-md-12 row">
                            <div class="col-lg-2 col-md-2 control-label text-r">VSDIGI-ID :</div>
                            <div class="col-md-2 control-label1">{{ itemid.generatedid }}</div>
                        </div>
                        <div class="col-md-12 row">
                            <div class="col-lg-2 col-md-2 control-label text-r">Name :</div>
                            <div class="col-md-2 control-label1">{{ itemid.name }}</div>
                        </div>
                        <div class="col-md-12 row">
                            <div class="col-lg-2 col-md-2 control-label text-r">Address :</div>
                            <div class="col-md-5 control-label1">
                                <display-first-address :addresses="itemid.addressess"></display-first-address>
                            </div>
                        </div>
                        <div class="form-group row CHK">
                            <label class=" col-lg-2 col-md-2 control-label text-r">
                                Contact :
                            </label>
                            <div class="col-lg-3 col-md-3 control-label1">
                                <display-mobiles :mobiles="itemid.mobiles"></display-mobiles>
                            </div>

                            <label class=" col-lg-3 col-md-3 control-label text-r">
                                Mode of Payment
                            </label>
                            <div class="col-lg-3 col-md-3">
                                <select class="form-control form-cascade-control input-small" v-model="mode">
                                    <option v-for="item in registers" :key="item.id" :value="item.id">{{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row CHK col-lg-2 col-md-2">
                            <button class="badge bg-sheme btn-xs" type="button" @click="bankdetails()">Add Payment
                                Details</button>
                        </div>
                        <div class="col-md-12 row">
                            <!-- <div class="col-md-6" v-if="bankopen">
                            <div class="form-group row CHK col-lg-4 col-md-2" >
                                <label for="fieldoption" class="control-label">Select Bank</label>
                                <select v-model="fieldoption" id="fieldoption" class="form-control form-cascade-control input-small" style="width:auto">
                                    <option :value="1">Bank</option>
                                    <option :value="2">GooglePay</option>
                                    <option :value="3">PhonePay</option>
                                    <option :value="4">Paytm</option>
                                    <option :value="5">Cash</option>
                                </select>
                            </div>
                        </div> -->
                            <template v-if="bankopen == false && getselectedbank != null">
                                <div class="col-md-6" v-for="item in getselectedbank" :key="item.id">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 control-label text-r">Name :</div>
                                        <div class="col-md-4 control-label1">
                                            {{ getMasterLabelByType(item.banklabelid, MASTER_BANK) }} </div>
                                    </div>
                                    <div class="row" v-if="item.accountno != null">
                                        <div class="col-lg-4 col-md-2 control-label text-r">
                                            {{ getMasterLabelByType(item.banklabelid, MASTER_BANK) }} :</div>
                                        <div class="col-md-4 control-label1">{{ item.accountno }}</div>
                                        <button class="btn btn-primary-edit" @click="bankedit(item)"><i
                                                class="fa fa-edit"></i></button>
                                    </div>
                                    <div class="row" v-if="item.ifsccode != null">
                                        <div class="col-lg-4 col-md-2 control-label text-r">Ifsc Code :</div>
                                        <div class="col-md-4 control-label1">{{ item.ifsccode }}</div>
                                    </div>
                                    <!-- <div class="row" v-if="item.gpay!=null">
                                <div class="col-lg-4 col-md-2 control-label text-r">Google Pay :</div>
                                <div class="col-md-4 control-label1">{{item.gpay}}</div>
                                <button class="btn btn-primary-edit" @click="bankedit(item)"><i class="fa fa-edit"></i></button>
                            </div>
                            <div class="row" v-if="item.phonepay!=null">
                                <div class="col-lg-4 col-md-2 control-label text-r">Phonepay :</div>
                                <div class="col-md-4 control-label1">{{item.phonepay}}</div>
                                <button class="btn btn-primary-edit" @click="bankedit(item)"><i class="fa fa-edit"></i></button>
                            </div>
                            <div class="row" v-if="item.paytm!=null">
                                <div class="col-lg-4 col-md-2 control-label text-r">Paytm :</div>
                                <div class="col-md-4 control-label1">{{item.paytm}}</div>
                                <button class="btn btn-primary-edit" @click="bankedit(item)"><i class="fa fa-edit"></i></button>
                            </div> -->

                                </div>
                            </template>
                            <div class="col-md-6">
                                <div class="form-group row CHK col-lg-4 col-md-2 row"
                                    v-if="fetchbankdetails != null && fetchbankdetails.length > 0">
                                    <label for="bankoptions" class="control-label">Select Payment</label>
                                    <select v-model="bankoptions" id="bankoptions" @change="getselectedbanks()"
                                        class="form-control form-cascade-control input-small" style="width:auto">
                                        <template v-if="fetchbankdetails != null">
                                            <option v-for="item in fetchbankdetails" :key="item.id" :value="item.id">
                                                <span>{{ getMasterLabelByType(item.banklabelid, MASTER_BANK) }}</span>
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7" v-if="bankopen">
                            <!-- <input v-if="fieldoption==1" class="form-cascade-control input-small" v-model="banklabelid" placeholder="Bank Name" type="text"> -->
                            <select v-model="banklabelid" class="form-cascade-control input-small"
                                @change="fieldoptions()">
                                <option :value="0">----Select---</option>
                                <option v-for="option in getmastertype(MASTER_BANK)" v-bind:key="option.labelid"
                                    v-bind:value="option.labelid" v-text="option.label" />
                            </select>
                            <input v-if="bankvalue != 3" class="form-cascade-control input-small" v-model="accountno"
                                placeholder="Number" type="number" style="margin-right:10px;margin-left:10px">
                            <input v-if="bankvalue == 1" class="form-cascade-control input-small" v-model="ifsccode"
                                placeholder="IFSC Code" type="test"><br>

                            <!-- <input v-if="fieldoption==2" class="form-cascade-control input-small" v-model="gpay" placeholder="Google Pay" type="number">
                        <input v-if="fieldoption==3" class="form-cascade-control input-small" v-model="phonepay" placeholder="Phone Pay" type="number">
                        <input v-if="fieldoption==4" class="form-cascade-control input-small" v-model="paytm" placeholder="Paytm" type="number"> -->
                            <div class="form-group row">
                                <div class="col-md-3"></div>
                                <div class="col-md-3 mt-10">
                                    <button type="button" class="btn btn-success btn-xs" style="margin-top:0"
                                        @click="submitbank()">Save</button>
                                    <button type="button" class="btn btn-danger btn-xs"
                                        @click="bankdetails()">Cancel</button>
                                </div>
                            </div>
                        </div>

                        <hr>
                        <!-- <div class="col-md-2">
                            <label for="" style="margin-right:10px ;">No. of Rec.</label>
                            <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                <option value=10>10</option>
                                <option value=25>25</option>
                                <option value=50>50</option>
                                <option value=100>100</option>
                            </select>
                        </div> -->
                        <div class="text-center">
                            <h5>PAY OUT DESCRIPTION</h5>
                        </div>
                        <div>
                            <div class="col-md-12 table-responsive">
                                <table class="table table-bordered table-hover table-striped display">
                                    <thead class="table-font" style="font-size:11px;">
                                        <tr>
                                            <th>#</th>
                                            <th>VSDIGI-ID</th>
                                            <th>ACC. NO</th>
                                            <th>NAME</th>
                                            <th class="visible-lg">Product</th>
                                            <th>PRICE</th>
                                            <th>DATE OF SALE</th>
                                            <th>% PAYOUT</th>
                                            <th>INCENTIVE</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-font" v-for="(item, index) in commissiondetails"
                                        v-bind:key="item.id">
                                        <tr>
                                            <td>{{ index + 1 }}</td>
                                            <td><span
                                                    v-if="item.saletransaction != null && item.saletransaction.user != null"><a
                                                        href="#">{{ item.saletransaction.user.generatedid }}</a></span>
                                                <span v-else><a href="#"
                                                        v-if="item.customer != null">{{ item.customer.generatedid }}</a></span>
                                            </td>
                                            <td><span
                                                    v-if="item.saletransaction != null && item.saletransaction.loandetails != null"><a
                                                        href="#">{{ item.saletransaction.loandetails.manualid }}</a></span>
                                                <span v-if="item.ongoingscheme != null"><a
                                                        href="#">{{ item.ongoingscheme.generatedid }}</a></span>
                                            </td>
                                            <td class="visible-lg"><span
                                                    v-if="item.customer != null">{{ item.customer.name }}</span></td>
                                            <td>
                                                <template
                                                    v-if="item.saletransaction != null && item.saletransaction.items != null">
                                                    <div v-for="items in item.saletransaction.items" :key="items.id">
                                                        <div v-for="company in items.product.attribute"
                                                            :key="company.id">
                                                            {{ getcompany(company.attribute_value_ids) }} </div>
                                                        {{ items.product.category.name }} {{ items.product.name }}
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div
                                                        v-if="item.ongoingscheme != null && item.ongoingscheme.scheme != null">
                                                        {{ item.ongoingscheme.scheme.name }}
                                                    </div>
                                                </template>
                                            </td>

                                            <!-- <td>{{item.saletransaction.amtafterdiscount}}/-</td> -->
                                            <td><span v-if="item.saletransaction != null"><i class='bx bx-rupee'></i>
                                                    {{ item.saletransaction.amtafterdiscount }}</span>
                                                <span v-else><i class='bx bx-rupee'></i>
                                                    {{ item.ongoingscheme.scheme.totalamount }}</span>
                                            </td>
                                            <td><span
                                                    v-if="item.saletransaction != null">{{ moment(item.saletransaction.transactiondate).format('DD-MM-YYYY') }}</span>
                                                <span
                                                    v-else>{{ moment(item.ongoingscheme.created_at).format('DD-MM-YYYY') }}</span>
                                            </td>
                                            <td><i class='bx bx-rupee'></i> {{ item.commtotalamt }} /-</td>
                                            <td><i class='bx bx-rupee'></i> {{ item.commtotalproduct }} /-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="col-md-12" style="margin-top: 20px">
                                <nav aria-label="Page navigation example" style="text-align: right !important">   
                                    <paginate
                                        style="margin: 0px !important; float: right"
                                        :page-count="noofpages"
                                        :click-handler="clickCallback"
                                        :prev-text="'&laquo;'"
                                        :next-text="'&raquo;'"
                                        :container-class="'pagination'">
                                    </paginate>
                                </nav>
                            </div> -->
                            <div class="form-group row CHK">
                                <!-- <label class=" col-lg-3 col-md-3 control-label text-r">
                                Payment Date
                            </label> -->
                                <div class="col-lg-6">

                                </div>
                                <label class=" col-lg-2 col-md-12 control-label text-r">
                                    Sale Payout:
                                </label>
                                <div class="col-lg-3 col-md-2 text-r">
                                    <input class="form-control form-cascade-control input-small" v-model="incentive"
                                        type="number">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row CHK">
                            <div class="col-lg-1"> </div>
                            <label class=" col-lg-7 col-md-12 control-label"><span style="margin-top:5px">1% Sales
                                    Payout</span></label>
                            <div class="col-md-3">

                                <input class="form-control form-cascade-control input-small" v-model="totalsale"
                                    type="number">
                            </div>
                        </div>
                        <div class="form-group row CHK">
                            <div class="col-lg-1"> </div>
                            <label class=" col-lg-3 col-md-12 control-label">Pay Out Duration Between:</label>
                            <div class="col-md-3" v-if="this.fromdate != ''">
                                <span class="control-label"
                                    style="font-size:14px;">{{ moment(this.fromdate).format("DD-MM-YYYY") }} TO
                                    {{ moment(this.todate).format("DD-MM-YYYY") }}</span>
                            </div>
                        </div>
                        <div class="form-group row CHK">
                            <div class="col-lg-1"> </div>
                            <label class="col-lg-7 col-md-6 control-label ">Additional Payout</label>
                            <div class="col-lg-3 col-md-9">
                                <input class="form-control form-cascade-control input-small" type="number"
                                    v-model="additionalpay">
                            </div>
                        </div>
                        <div class="form-group row CHK">
                            <div class="col-lg-1"> </div>
                            <label class="col-lg-5 col-md-6 control-label ">Additional Gift</label>
                            <div class="col-lg-2 col-md-6">
                                <select class="form-control" v-model="gifttype">
                                    <option :value="1">Gift Item</option>
                                    <option :value="2">Amount</option>
                                    <option :value="3">Recharge</option>
                                </select>
                            </div>
                            <div class="col-lg-3 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-if="gifttype == 1"
                                    v-model="additionalgift" type="text">
                                <input class="form-control form-cascade-control input-small" v-if="gifttype == 3"
                                    placeholder="Recharge Amount" v-model="additionalgift" type="number">
                                <input class="form-control form-cascade-control input-small" v-if="gifttype == 2"
                                    v-model="additionalgift" type="number">
                                <input class="form-control form-cascade-control input-small" v-if="gifttype == 3"
                                    v-model="mobileno" placeholder="Enter Mobile No." type="number">
                            </div>
                        </div>
                        <div class="form-group row CHK">
                            <div class="col-lg-1"> </div>
                            <label class="col-lg-7 col-md-6 control-label ">Additional Scheme</label>
                            <div class="col-lg-3 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="additionalscheme"
                                    type="text">
                            </div>
                        </div>
                        <hr>
                        <div class="form-group row">
                            <label class="col-lg-8 col-md-3 control-label text-r">
                                Additional Gift</label>
                            <div class="col-lg-3 col-md-9">
                                <input class="form-control form-cascade-control input-small" disabled
                                    v-model="additionalgift" cols="20" rows="2">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-8 col-md-3 control-label text-r">
                                Additional Scheme</label>
                            <div class="col-lg-3 col-md-9">
                                <input class="form-control form-cascade-control input-small" disabled
                                    v-model="additionalscheme" cols="20" rows="2">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-8 col-md-3 control-label text-r">
                                G.Total</label>
                            <div class="col-lg-3 col-md-9">
                                <input type="text" disabled class="form-control form-cascade-control input-small"
                                    v-model="paidamount" cols="20" rows="2">
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="col-lg-10 col-md-3 control-label text-r">
                                Remark</label>
                            <div class="col-lg-12 row">
                                <div class="col-lg-6 row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-3">
                                        <label class="control-label">Payment Date</label>
                                    </div>
                                    <div class="col-md-7">
                                        <!-- <input class="form-control form-cascade-control input-small"
                                            v-model="transactiondate" type="date"> -->
                                        <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="transactiondate">
                                            <template v-slot="{ inputValue, togglePopover }">
                                                <div class="flex items-center">
                                                    <button type="button"
                                                        class="date-picker"
                                                        @click="togglePopover()">
                                                        <i class='bx bx-calendar'></i>
                                                    </button>
                                                    <input :value="inputValue" @click="togglePopover()"
                                                        class="form-control form-cascade-control input-small"
                                                        placeholder="DD/MM/YYYY" readonly />
                                                </div>
                                            </template>
                                        </vc-date-picker>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-9">
                                    <textarea class="form-control form-cascade-control input-small" v-model="remark"
                                        cols="20" rows="2" style="height:83px;"></textarea>
                                    <span>Total Outstanding: <span
                                            style="font-weight:700">{{ getremainingamt }}</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row row leadbycustomer" style="display: none">
                            <div class="col-lg-6 col-md-9">
                                <span id="fill"></span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3"></div>
                            <div class="col-md-3">
                                <button type="button" class="btn btn-primary" style="margin-top:0"
                                    @click="submit()">Submit</button>
                                <button type="button" class="btn btn-danger" @click="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Constants from '../../components/utilities/Constants.vue'
// import Paginate from "vuejs-paginate";
export default {
    mixins: [Constants],
    props: ['itemid', 'newpayment'],
    components: {
        DisplayFirstAddress, DisplayMobiles
    },
    data() {
        return {
            moment: moment,
            totalsale: 0,
            saleamt: 0,
            addgift: '',
            todate: '',
            fromdate: '',
            addpayout: 0,
            transactiondate: moment(new Date()).format('YYYY-MM-DD'),
            mode: 0,
            additionalpay: 0.0,
            additionalgift: '',
            remark: '',
            commissiondetails: [],
            incentive: 0,
            additionalscheme: '',
            totalremainingamt: 0,
            ledgerbal: [],
            gifttype: 1,
            bankopen: false,
            banklabelid: '',
            accountno: '',
            ifsccode: '',
            paytm: '',
            gpay: '',
            phonepay: '',
            bankid: 0,
            fetchbankdetails: [],
            bankoptions: 0,
            getselectedbank: [],
            fieldoption: 5,
            bankvalue: 0,
            mobileno: '',
            id: 0,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            // currentpage:1,
            // noofrec:10,
            totalfetchcount:0
        }
    },
    // watch:{
    //     bankoptions:function(){
    //         if(this.fetchbankdetails!=null){
    //             console.log(this.fetchbankdetails[0])
    //             return this.fetchbankdetails[0];
    //         }
    //         // console.log(this.bankoptions)
    //     }
    // },
    computed: {
        gettotalamt() {
            if (this.ledgerbal != null) {
                let sumamt = 0;
                this.ledgerbal.forEach(element => {
                    if (element.inout == 2) {
                        if (element.customerid == this.itemid.id) {
                            sumamt = (sumamt - 0) + (element.amount - 0)
                        }
                    }
                });
                return sumamt;
            } else {
                return 0
            }
        },
        getpaidamt() {
            if (this.ledgerbal != null) {
                let sumamt = 0;
                this.ledgerbal.forEach(element => {
                    if (element.inout == 1) {
                        if (element.customerid == this.itemid.id) {
                            sumamt = (sumamt - 0) + (element.amount - 0)
                        }
                    }
                });
                return sumamt;
            } else {
                return 0
            }
        },
        getremainingamt() {
            return (this.gettotalamt - this.getpaidamt).toFixed(2)
        },
        paidamount() {
            if (this.totalsale != null && this.incentive != null) {
                if (this.additionalpay != null && this.incentive != null) {
                    let amt = parseFloat(this.totalsale) + parseInt(this.additionalpay) + parseFloat(this.incentive);
                    if (this.gifttype == 2 && this.additionalgift != '') {
                        amt = amt + parseInt(this.additionalgift);
                    }
                    return amt
                } else {
                    return parseFloat(this.totalsale)
                }
            } else {
                return 0.00
            }
        },
        ...mapGetters([
            'registers', 'attributes', 'master', 'edititem'
        ]),
        // noofpages(){
        //     return Math.ceil(parseInt(this.totalfetchcount) / parseInt(this.noofrec))
        // },
    },
    mounted() {
        if (this.edititem != null && this.newpayment != 1) {
            this.initializeitem();
            console.log(this.edititem)
        } else {
            this.$store.commit("assignedititem", null)
            this.refresh();
            console.log(this.edititem)
        }
    },
    methods: {
        // clickCallback (currentpage) {
        //     this.currentpage=currentpage
        //     this.$store.commit("assigncurrentpage",currentpage);
        //     this.fetchbal();
        // },
        initializeitem() {
            this.$store.dispatch("fetchregisters")
            this.fetchdetailsbank();
            this.id = this.edititem.id
            this.todate = this.edititem.todate
            this.fromdate = this.edititem.fromdate
            this.mode = this.edititem.mode
            this.incentive = this.edititem.incentive
            this.totalsale = (parseFloat(this.edititem.amount) - parseFloat(this.edititem.incentive)).toFixed(2);
            this.additionalpay = this.edititem.additionalpay
            this.additionalgift = this.edititem.additionalgift
            this.gifttype = this.edititem.gifttype
            this.remark = this.edititem.remark
            this.additionalscheme = this.edititem.additionalscheme
            this.transactiondate = this.edititem.transactiondate
            if (this.edititem.userbank != null) {
                this.fetchbankdetails = this.edititem.userbank
            }
            this.mobileno = this.edititem.mobileno
            if (this.edititem.commissionpayment != null) {
                this.commissiondetails = this.edititem.commissionpayment
            }
        },
        fieldoptions() {
            let required = this.master.filter(a => a.type == 21)
            // console.log(required)
            required.forEach(element => {
                if (element.labelid == this.banklabelid) {
                    if (element.value1 == 1) {
                        this.bankvalue = 1
                    } else if (element.value1 == 5) {
                        this.bankvalue = 3
                    } else {
                        this.bankvalue = 2
                    }
                }
            });
        },
        getselectedbanks() {
            if (this.fetchbankdetails != null && this.fetchbankdetails.length > 0) {
                this.getselectedbank = this.fetchbankdetails.filter(a => a.id === this.bankoptions)
            } else {
                return ''
            }
        },
        cancel() {
            this.$parent.showpaymentform = false
        },
        fetchbal() {
            if (this.todate != null && this.fromdate != null) {
                this.$store.commit("assignloadingstatus", 1)
                let param = { id: this.itemid.id, todate: this.todate, fromdate: this.fromdate, paymentstatus: 0,noofrec:this.noofrec??100,currentpage:this.currentpage??1 }
                this.$http.post('api/fetch/commissionbaldatewise', param)
                    .then((response) => this.processDetailResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
            }
        },
        processDetailResponse(data) {
            this.$store.commit("assignloadingstatus", 0)
            //console.log("sum",data.totalbal)
            this.totalsale = data.totalbal
            this.totalfetchcount=data.count
            //this.$store.commit("assigncommissiondetails",data.data)
            this.commissiondetails = data.data
            if (this.commissiondetails != null && this.commissiondetails.length > 0) {
                let productcomm = 0
                this.commissiondetails.forEach(element => {
                    productcomm = (productcomm - 0) + (element.commtotalproduct - 0)
                });
                this.incentive = productcomm
            } else {
                this.todate = ''
                this.fromdate = ''
            }
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                    (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                    return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        submit() {
            this.$store.commit("assignloadingstatus", 1)
            if (this.getselectedbank != null && this.getselectedbank.length > 0) {
                this.banklabelid = this.getselectedbank[0].banklabelid;
                this.accountno = this.getselectedbank[0].accountno;
                this.ifsccode = this.getselectedbank[0].ifsccode;
            }
            let commissionvalue = parseFloat(this.totalsale) + parseFloat(this.incentive)
            let param = {
                inout: 2, fy: 2022, customerid: this.itemid.id, incentive: this.incentive, transactionid: this.id,
                amount: commissionvalue, transactiondate: this.transactiondate, trantype: 4, mode: this.mode,
                paymenttype: 14, additionalpay: this.additionalpay, additionalgift: this.additionalgift, gifttype: this.gifttype,
                remark: this.remark, userbankid: this.bankoptions, fromdate: this.fromdate, todate: this.todate, mobileno: this.mobileno,
                additionalscheme: this.additionalscheme, banklabelid: this.banklabelid, accountno: this.accountno, ifsccode: this.ifsccode
            }

            this.$http.post('api/postpayment', param)
                .then((response) => this.processpaymentResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processpaymentResponse(data) {
            this.$parent.showpaymentform = false
            this.$store.commit("assignloadingstatus", 0)
            console.log(data)
            if (this.edititem == null) {
                this.$store.commit('assignadvisorid', this.itemid)
                this.$router.push({ name: 'commissionpayment' })
            }
        },
        processpaymentupdateResponse(data) {
            console.log(data)
        },
        submitbank() {
            let param1 = { id: this.bankid, userid: this.itemid.id, accountno: this.accountno, banklabelid: this.banklabelid, ifsccode: this.ifsccode }
            this.$http.post('api/bank/create', param1)
                .then((response) => this.processpaymentbankResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        fetchdetailsbank() {

            let param1 = { userid: this.itemid.id }
            this.$http.post('api/bank/fetch', param1)
                .then((response) => this.processfetchtbankResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processfetchtbankResponse(data) {
            this.fetchbankdetails = data
            if (this.fetchbankdetails != null && this.fetchbankdetails.length > 0) {
                this.bankoptions = this.fetchbankdetails[0].id;
            }
            this.getselectedbanks();
        },
        processpaymentbankResponse() {
            this.bankdetails();
            this.refresh();
        },
        refresh() {
            this.fetchdetailsbank();
            this.$store.dispatch("fetchregisters")
            this.$store.dispatch("fetchattributes")
            // let param1 = {id:this.itemid.id}
            // this.$http.post('api/fetch/commission/commissionleft',param1)
            //     .then((response) => this.processResponse(response.data))
            //     .catch((err) => {
            //         console.log('', err)
            //     });
            let param = { customerid: this.itemid.id }
            this.$http.post('api/fetch/commission/fetchledger', param)
                .then((response) => this.processledgerResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        // processResponse(data){
        //     this.totalremainingamt=data
        // },
        processledgerResponse(data) {
            this.ledgerbal = data
        },
        bankdetails() {
            this.bankopen = !this.bankopen
            this.banklabelid = ''
            this.accountno = ''
            this.ifsccod = ''
            this.paytm = ''
            this.gpay = ''
            this.phonepay = ''
            this.bankid = 0
        },
        bankedit(item) {
            this.bankopen = true
            this.banklabelid = item.banklabelid
            this.accountno = item.accountno
            this.ifsccod = item.ifsccode
            this.paytm = item.paytm
            this.gpay = item.gpay
            this.phonepay = item.phonepay
            this.bankid = item.id
        },
        
    },
}
</script>
<style scoped>
.control-label1 {
    margin-top: 7px;
    font-size: 13px;
}

.btn-primary-edit {
    background: transparent;
    color: green;
    border: none;
    font-size: 17px;
}
</style>