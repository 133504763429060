<template>
<div>
    <div v-if="localviewno==1">
        <div class="text-center page-header">
            <h3>Commission Transaction Balance  </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-12 row detailborder">
                <div class="col-md-4">
                    <span style="font-weight:700">Total Amt:</span> <span class="amt">{{advisorid.totalamount}}</span>
                </div>
                <div class="col-md-4 text-center">
                    <span style="font-weight:700">Paid Amt:</span ><span class="amt">{{advisorid.totalpaid}}</span>
                </div>
                <div class="col-md-4 text-right">
                    <span style="font-weight:700">Rem. Amt:</span><span class="amt"> {{advisorid.balance}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-cascade pt-20">
            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-4 row">
                        <label><strong>From Date</strong></label>
                        <!-- <input type="date" class="form-cascade-control input-box-short" v-model="fromdate"> -->
                        <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="fromdate">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <button type="button"
                                        class="date-picker"
                                        @click="togglePopover()">
                                        <i class='bx bx-calendar'></i>
                                    </button>
                                    <input :value="inputValue" @click="togglePopover()"
                                        class="form-control form-cascade-control input-small"
                                        placeholder="DD/MM/YYYY" readonly />
                                </div>
                            </template>
                        </vc-date-picker>
                    </div>
                    <div class="col-md-4 row">
                        <label><strong>To Date</strong></label>
                        <!-- <input type="date" class="form-cascade-control input-box-short" v-model="todate"> -->
                        <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="todate">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <button type="button"
                                        class="date-picker"
                                        @click="togglePopover()">
                                        <i class='bx bx-calendar'></i>
                                    </button>
                                    <input :value="inputValue" @click="togglePopover()"
                                        class="form-control form-cascade-control input-small"
                                        placeholder="DD/MM/YYYY" readonly />
                                </div>
                            </template>
                        </vc-date-picker>
                    </div>
                    <div class="col-md-2">
                        <button @click="search()" class="btn bg-success text-white username btnsearch"  type="button">Search</button>
                    </div>
                    <div class="col-md-2 text-right">
                        <button @click="paymenthistory()" class="btn bg-success text-white username btnsearch" type="button">Payment History</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 flex-between-row pt-20">
            <div class="flex-row">
                <label for="" style="width:150px"><strong>Advisor Name:</strong></label>
                <div class="margin-l-10">{{advisorid.customer.name}}</div>
            </div>
            <div class="flex-row">
               <label for="" style="width:200px"><strong>No. of Rec.</strong> </label>
                <select class="form-control col-mb-1 form-cascade-control margin-l-10" v-model="noofrec" id="rec" @change="refresh()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option>
                </select>
            </div>
        </div>
        <div class="col-md-12 table-responsive">
            <div class=" panel">
                <div class="  panel-body" style="overflow-x:auto;padding-left:0px">
                    <table class="table table-bordered table-hover table-striped display">
                        <thead class="table-font" style="font-size:13px;">
                            <tr>
                                <th>#</th>
                                <th >CUSTOMER ID</th>
                                <th  >NAME</th>
                                <th >ACC. NO</th>
                                <th class="visible-lg">Product</th>
                                <th>Sale Value</th>
                                <th>% Payout</th>
                                <th>INCENTIVE</th>
                                <th>Date oF Sale</th>
                                <th>Entry By</th>
                                <th>Remark</th>
                            </tr>
                        </thead>
                        <tbody class="table-font" v-if="commissiondata.length>0">
                            <tr v-for="(item,index) in commissiondata" v-bind:key="item.id">
                                <td>{{((currentpage-1) * noofrec) + (index+1)}}</td>
                                <td><span v-if="item.saletransaction!=null && item.saletransaction.user!=null"><a href="#">{{item.saletransaction.user.generatedid}}</a></span>
                                    <span v-else><a href="#" v-if="item.customer!=null">{{item.customer.generatedid}}</a></span></td>
                                <td class="visible-lg"><span v-if="item.customer!=null">{{item.customer.name}}</span></td>
                                <td><span v-if="item.saletransaction!=null && item.saletransaction.loandetails!=null"><a href="#">{{item.saletransaction.loandetails.manualid}}</a></span> 
                                    <span v-if="item.ongoingscheme!=null"><a href="#">{{item.ongoingscheme?.generatedid}}</a></span></td> 
                                <td>
                                    <template v-if="item.saletransaction!=null && item.saletransaction.items!=null">
                                        <div v-for="items in item.saletransaction.items" :key="items.id">
                                            <div v-for="company in items.product.attribute" :key="company.id">
                                                {{ getcompany(company.attribute_value_ids) }} </div>
                                                {{ items.product.category.name }} {{ items.product.name }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="item.ongoingscheme !=null && item.ongoingscheme.scheme!=null">
                                        {{item.ongoingscheme?.scheme?.name}}
                                        </div>
                                    </template>
                                </td>
                                <td><span v-if="item.saletransaction!=null"><i class='bx bx-rupee'></i> {{item.saletransaction.amtafterdiscount}}</span>
                                    <span v-else><i class='bx bx-rupee'></i> {{item.ongoingscheme?.scheme?.totalamount}}</span></td>
                                <td><i class='bx bx-rupee'></i> {{item.commtotalamt}}</td>
                                <td><i class='bx bx-rupee'></i> {{item.commtotalproduct}}</td>
                                <td><span v-if="item.saletransaction!=null">{{moment(item.saletransaction.transactiondate).format('DD-MM-YYYY')}}</span>
                                    <span v-else>{{moment(item.ongoingscheme.created_at).format('DD-MM-YYYY')}}</span></td>
                                <td><span v-if="item.saletransaction!=null">{{item.saletransaction.enteredby.name}}</span>
                                    <span v-else>{{item.ongoingscheme?.enteredby?.name}}</span></td>
                                <td><span v-if="item.paymentstatus==null || item.paymentstatus==0" style="color:Red">UNPAID</span>
                                <span v-else style="color:green">Settle</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12" style="margin-top: 20px">
                <nav aria-label="Page navigation example" style="text-align: right !important">   
                    <paginate
                        style="margin: 0px !important; float: right"
                        :page-count="noofpages"
                        :click-handler="clickCallback"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'">
                    </paginate>
                </nav>
            </div>
        </div>
    </div>
    <!-- <commission-payment :id="this.advisorid" v-if="localviewno==2"></commission-payment> -->
</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Paginate from "vuejs-paginate";
import Constants from '../../components/utilities/Constants.vue'
// import CommissionPayment from './CommissionPayment.vue'
export default {
    mixins:[Constants],
    components:{
        // CommissionPayment,
        Paginate},
    data() {
        return {
            totalamount:0,
            moment:moment,
            todate:'',
            fromdate:'',
            ledgerbal:[],
            localviewno:1,
            currentpage:1,
            noofrec:10,
            commissioncount:0,
            commissiondata:[],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
        }
    },
    computed:{
        ...mapGetters([
		      'viewno','downlinecount','commissiondetails','attributes','advisorid'
	    ]),
        noofpages(){
            return Math.ceil(parseInt(this.commissioncount) / parseInt(this.noofrec))
        },
    },
    mounted() {
        this.refresh();
    },
    methods:{
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh();
        },
        back(){
           this.$store.commit('assignviewno',1)
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        search(){
            this.$store.commit("assignloadingstatus",1)
            let param = {id:this.advisorid.customerid,todate:this.todate,fromdate:this.fromdate,
                currentpage:this.currentpage,noofrec:this.noofrec}
                this.$http.post('api/fetch/commission',param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
                this.$http.post('api/fetch/commission/commissioncount',param)
                .then((response) => this.processDetailCountResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processDetailResponse(data){
            this.$store.commit("assignloadingstatus",0)
            // this.$store.commit("assigncommissiondetails",data)
            this.commissiondata=data
        },
        processDetailCountResponse(data){
            this.commissioncount=data
        },
        refresh(){
            this.search();
            this.$store.dispatch("fetchattributes")
        },
        processledgerResponse(data){
            this.ledgerbal=data
        },
        paymenthistory(){
            // this.localviewno=2
            this.$router.push({path:'commissionpayment'})
        },
       
    }
}
</script>
<style scoped>
.detailborder{
    padding: 15px;
    background-color: #eab132f5;
}
.amt{
    color:#485259;
    font-weight: 700;
}
</style>